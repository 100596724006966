import React, { useState } from "react"
import { graphql } from 'gatsby'
import LayoutPublic from "../components/layouts/layoutPublic"
import { Box, Flex, Grid, Heading, Stack, Spinner } from "@chakra-ui/react"
import Seo from '../components/Seo/';
import SellerContactForm from "../components/ad/SellerContactForm";
import StructuredProduct from "../components/StructuredData/product";
import StructuredBreadcrumbs from "../components/StructuredData/breadcrumbs";
import AdCardPortraitLarge from "../components/ad/PortraitLarge";
import HrefLang from "../utils/HrefLang.js"
import VisibilitySensor from 'react-visibility-sensor'
import Loadable from 'react-loadable'
import ModelAlertSubscribeForm from "../components/Alerts/ModelAlertSubscribeForm"
import Nav from "../components/nav"
import { navigate } from 'gatsby'

const AdTpl = (props) => {
  const { ad, adOtherLang } = props.data;
  const lang = process.env.GATSBY_LANG;
  const
    [isContactOpen, setContactOpen] = useState(false),
    [isRelatedAdsVisible, setRelatedAdsVisible] = useState(false)

  const {
    contentful_id,
    name,
    refModel,
    refUnivers,
    refVersion,
    refBrand,
    slug,
    region,
    images,
    pictures
  } = props.data.ad
  const waterMarkParamFacebook = '?width=1200&height=600&olurl=https://origin.wanaboat.fr/svg/logo-white.svg&olscale=50&olalign=centercneter&olalpha=60&extend=40&bg=0042da';
  // console.log('childContentfulUniversDescShortTextNode', childContentfulUniversDescShortTextNode.descShort )
  const adsBecameVisible = (isVisible) => {
    if (isVisible && !isRelatedAdsVisible) {
      setRelatedAdsVisible(true)
    }
  }

  const AsyncRelatedAdsPanel = (1 === 1) ? Loadable({
    loader: () => import('../components/ad/AsyncRelatedAdsPanel'),
    loading() {
      return <Flex
        mx={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
        justify="center"
        border="dashed 2px"
        borderRadius="lg"
        borderColor="gray.100"
        background="gray.50"
        p="1rem"
        my="1rem"
        key={0}><Spinner color="blue.500" size="sm" />
      </Flex>
    }
  }) : null

  const title = {
    "en": name,
    "fr": name
  };
  const description = {
    "en":
      name + ' at the best used price. Photos and descriptions of the boats for sale ads price, purchase, selling.'
    ,
    "fr":
      name + " au meilleur prix d'occasion. Photos et descriptions du bateaux à vendre annonces prix, achat, vente"
  };
  return (


    <LayoutPublic lang={lang}>
      {adOtherLang ? <HrefLang slug={adOtherLang.slug} /> : null}
      <Seo
        title={title[lang]}
        description={description[lang]}
        slug={slug}
        image={(pictures !== null) ? `https://assets.wanaboat.fr/${pictures.url[0]}${waterMarkParamFacebook}` : null}
      />
      <StructuredProduct
        name={ad.name}
        price={(ad.price) ? ad.price : 0}
        refBrand={ad.refBrand}
        refModel={ad.refModel}
        images={(ad.pictures !== null) ? ad.pictures : null}
        slug={ad.slug}
      />

      <Stack
        spacing={{ base: 0, lg: 10 }}
        mb={{ base: 2, lg: 8 }}
      >
        <Nav />
        <StructuredBreadcrumbs
          items={[
            refUnivers,
            refBrand,
            refModel,
            refVersion ? { "name": refVersion.name, "slug": refVersion.slug } : null,
            { "name": name, "slug": slug }]}
        />
        <Heading
          isTruncated
          as="p"
          color={{ base: "white", lg: "gray.700" }}
          backgroundColor={{ base: "blue.800", lg: "transparent" }}
          p={{ base: ".5rem .25rem", lg: 0 }}
          pb={{ base: ".5rem", lg: "1rem" }}
          fontSize={{ base: 18, lg: 32 }}
          mb={0}
          textAlign={{ base: "center", lg: "left" }}
          marginBottom="0"
          display={{ base: 'none', lg: 'block' }}
        >{name}</Heading>


        <Grid
          gridTemplateColumns={{
            base: "100%",
            lg: "700px calc(100% - 700px)"
          }}
        >
          {(isContactOpen) ?
            <SellerContactForm



              close={() => { setContactOpen(!isContactOpen) }}
              id={ad.contentful_id}
              adBackendId={ad.backendId}
              sellerId={ad.sellerId}
            />
            :
            <AdCardPortraitLarge
              contactOpen={() => { setContactOpen(!isContactOpen) }}
              ad={ad}
              id={ad.id}
              contentful_id={contentful_id}
              closeButtonLabel="Retour"
              context="main-website-ad"
              backAction={() => navigate('../')}

            />
          }
          <Box
            as="aside"
            w={{ base: "100%", lg: "100%" }}
            p={{ base: "1rem", lg: "0rem" }}
            pl={{ base: "1rem", lg: "2rem", xl: "3rem" }}
            mt={{ base: 0, lg: '3rem' }}
          >
            {/* <VisibilitySensor
              onChange={adsBecameVisible}
            > */}
            <Stack shouldWrapChildren={true} spacing='1rem'>
              {/* <ModelAlertSubscribeForm name={props.data.ad.refModel.name} modelID={props.data.ad.refModel.contentful_id} placeholer={props.data.ad.refModel.vipEmailExample} /> */}
              {AsyncRelatedAdsPanel ?
                <AsyncRelatedAdsPanel currentAdID={contentful_id} modelID={props.data.ad.refModel.contentful_id} />
                : null}
            </Stack>
            {/* </VisibilitySensor> */}
            {/* <Heading fontSize="14px" mb="1rem" textTransform="uppercase" fontWeight="normal" letterSpacing="0.065rem" as="h3">Autres annonces similaires ({refModel.name})</Heading>
            { ads && ads.edges ? ads.edges.map((edge, i) =>
              <Box mb="1rem">
                <AdCardLandscapeSmaller
                  name={edge.node.name}
                  images={edge.node.images}
                  key={edge.node.id}
                  region={edge.node.region}
                  price={edge.node.price}
                  to={edge.node.slug}
                />
              </Box>
            ) : null } */}
          </Box>
        </Grid>
      </Stack>
    </LayoutPublic>
  )
}

export default AdTpl

export const pageQuery = graphql`
  query adQuery( $slug: String!, $id: String!, $otherLang: String!){
    ad:contentfulAd(
        slug: {eq: $slug}
      ) {
      id
      contentful_id
      backendId
      name
      price
      slug
      publicationDate
      date(formatString: "Y")
      city
      department
      documents
      region
      userId
      sellerId
      videoUrl
      childContentfulAdContentTextNode{
        content
      }
      location{
        lat,
        lon
      }
      #images{
      #  url
      #}
      pictures{ url }
      #documentsList { url }
      refModel{
        slug
        name
        contentful_id
        vipEmailExample
      }
      refVersion{
        slug
        name
      }
      refUnivers
      {
        slug
        name
      }
      refBrand
      {
        slug
        name
      }
      user{
        contentful_id
      }
      childContentfulAdImagesJsonNode {
        id
        internal {
          content
          description
          ignoreType
          mediaType
        }
      }
    }
    adOtherLang:contentfulAd(
      contentful_id: {eq: $id}
      node_locale: { eq: $otherLang }
    ) {
      id
      slug
    }
    ads: allContentfulAd(
      filter: {
        refUnivers: { slug: { eq: $slug } }
        node_locale: { eq: "fr" }
      }
      limit: 6
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          id
          contentful_id
          name
          slug
          price
          department
          region
          isBoat
          isSail
          isPart
          isTrolley
          refModel{ name }
          refBrand { name }
          refUnivers { slug }
          images { url }
          pictures { url }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
         
        }
      }
    }
  }
`